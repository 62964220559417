body {
  margin: 0;
  font-family: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.vanilla-jsoneditor-react {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%
}
.jse-main {
  flex: 1;
}

.fullpage {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.bottom-status-bar {
  height: 18px;
  overflow-x: hidden;
  white-space: nowrap;
}

.status-bar {
  flex-grow: 0;
  flex-shrink: 0;
  padding: 8px 8px;
  background: #3883fa;
  color: white;
  text-align: center;
  font-size: 15px;
  display: flex;
  gap: 10px;
}

.error {
  background: #fa3855;
}

.small {
  font-size: 14px;
}

.space {
  flex: 1;
}

a {
  color: #98ffff;
}